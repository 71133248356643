import React from "react";
import Slider from "react-slick";
import "../assets/css/fbanner.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import quick from "../assets/images/quick.png";
import yoonmin from "../assets/images/yoonmin.png";
import barunjiib from "../assets/images/barunjiib.png";
import babyci from "../assets/images/babyci.svg";
import fldici from "../assets/images/fldici.png";
import YesonFci from "../assets/images/YesonFci.svg";
import zerowebci from "../assets/images/zerowebci.svg";
import Thesuitesci from "../assets/images/Thesuitesci.svg";
import { slideSettings } from "../utils/modules";

const logo = [
  zerowebci,
  babyci,
  fldici,
  YesonFci,
  Thesuitesci,
  quick,
  barunjiib,
  yoonmin,
];

function FBannerBox({ image }) {
  return (
    <div className="fbanner-box">
      <img src={image} alt="zeroweb CI" />
    </div>
  );
}

export default function FBanner() {
  return (
    <div className="fbanner">
      <div className="fbanner-in">
        <Slider {...slideSettings}>
          {logo.map((item) => (
            <FBannerBox image={item} />
          ))}
        </Slider>
      </div>
    </div>
  );
}
