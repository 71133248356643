import React from "react";
import logo from "../assets/images/img-logo.svg";
import { goMarket } from "../utils/modules";

export default function Header() {
  return (
    <header>
      <div className="header_inner width140 flex justify alignC">
        <div className="logo">
          <a href="/">
            <img src={logo} alt="" />
          </a>
        </div>
        <button onClick={goMarket}>앱 다운로드</button>
      </div>
    </header>
  );
}
