import React, { useState } from "react";
import Swal from "sweetalert2";
import "../assets/css/contact.css";
import { handlePolicy, sendDiscord, validateForm } from "../utils/modules";

export default function Contact() {
  const [policyCheck, setPolicyCheck] = useState(false);
  const [formState, setFormState] = useState({
    name: "",
    contact: "",
    details: "",
  });
  const { name, contact, details } = formState;
  const postData = {
    content: null,
    embeds: [
      {
        title: "\b문의가 도착했어요! 📩",
        color: 5814783,
        fields: [
          { name: "연락처 ☎️", value: `이름: ${name}\n전화번호: ${contact}` },
          { name: "요청사항💬", value: details },
        ],
      },
    ],
    username: "헬퍼잇 제휴 알림이",
    avatar_url:
      "https://play-lh.googleusercontent.com/s-3LdW3oI4DjOTdLvdiWgXjLUvxG_zhek5ITJGejetf0oWOuFD-5w9KnzBVklDZXGhU=w240-h480-rw",
  };
  const message = validateForm(formState);
  const updateFormState = (key, value) =>
    setFormState((prevState) => ({ ...prevState, [key]: value }));

  // 제출 처리
  const handleSubmit = async () => {
    if (message) {
      Swal.fire({ text: message, icon: "info", confirmButtonText: "확인" });
      return;
    }
    if (!policyCheck) {
      Swal.fire({
        text: "개인정보 처리방침에 동의해주세요",
        icon: "info",
        confirmButtonText: "확인",
      });
      return;
    }
    const response = await sendDiscord(postData);
    if (response.ok) {
      // 상태 초기화
      setFormState({ name: "", contact: "", details: "" });
      Swal.fire({
        title: "신청이 완료되었어요!",
        text: "영업일 기준 2~3일 소요됩니다",
        icon: "success",
        confirmButtonText: "확인",
      });
    }
  };

  return (
    <section id="contact-form">
      <div className="contact-section-in">
        <div className="title_box">
          <div className="title">
            <h1 className="title_p">헬퍼잇에 문의하세요</h1>
          </div>
        </div>
        <div className="contact_from_box">
          <ul>
            <li>연락 받으실 정보</li>
            <li>
              <input
                className="text01"
                type="text"
                name="name"
                placeholder="이름"
                value={name}
                onChange={(e) => updateFormState("name", e.target.value)}
              />
              <input
                className="text01"
                type="number"
                name="tel"
                placeholder="전화번호"
                value={contact}
                onChange={(e) => updateFormState("contact", e.target.value)}
              />
            </li>
            <li>요청사항</li>
            <li>
              <textarea
                className="text01"
                name="name"
                placeholder="요청사항을 입력해주세요"
                value={details}
                onChange={(e) => updateFormState("details", e.target.value)}
              />
            </li>
            <li>
              <div id="agreement">
                <div>
                  <input
                    type="checkbox"
                    id="privacy"
                    className="checkbox01"
                    style={{ marginRight: "10px" }}
                    value={policyCheck}
                    onClick={() => setPolicyCheck(!policyCheck)}
                  />
                  <p style={{ display: "inline-block", fontSize: "0.8em" }}>
                    [필수] 개인정보 수집 및 이용 동의
                  </p>
                </div>
                <span
                  onClick={handlePolicy}
                  style={{ cursor: "pointer", fontSize: "0.6em" }}
                >
                  ※ 약관보기
                </span>
              </div>
              <br />
              <div id="agreement">
                <div>
                  <input
                    type="checkbox"
                    id="privacy"
                    className="checkbox01"
                    style={{ marginRight: "10px" }}
                  />
                  <p style={{ display: "inline-block", fontSize: "0.8em" }}>
                    [선택] 마케팅 활용 동의
                  </p>
                </div>
                <span style={{ cursor: "pointer", fontSize: "0.6em" }}>
                  ※ 약관보기
                </span>
              </div>
            </li>
          </ul>
          <button className="button_01 button" onClick={handleSubmit}>
            제휴 문의 접수
          </button>
        </div>
      </div>
    </section>
  );
}
