import React from "react";
import video from "../assets/videos/sec01_bg.mp4";
import apple from "../assets/images/ico-apple.svg";
import google from "../assets/images/ico-google.svg";
import sec01 from "../assets/images/img-sec01-app.png";
import arrow from "../assets/images/right-arrow.svg";
import { appleHandler, cleanHandler, googleHandler } from "../utils/modules";

export default function Section01() {
  return (
    <section className="sec01 relative">
      <video autoPlay loop muted playsInline>
        <source src={video} type="video/mp4" />
      </video>
      <div
        className="sec01_inner width140 relative"
        data-aos="fade-up"
        data-aos-offset="200"
        data-aos-easing="ease-in-out"
        data-aos-duration="800"
      >
        <div className="left">
          <h1 className="mb40">
            도움이 필요할 땐,
            <br />
            헬퍼잇
          </h1>
          <p className="mb80">바쁜 일상 속에서 사람들의 시간을 공유합니다.</p>
          <div className="btns flex alignC">
            <button className="apple_btn flex alignC" onClick={appleHandler}>
              <img src={apple} alt="" />
              App Store
            </button>
            <button className="google_btn flex alignC" onClick={googleHandler}>
              <img src={google} alt="" />
              Google Play
            </button>
          </div>
          <div className="btns flex alignC">
            <button className="clean_btn flex alignC" onClick={cleanHandler}>
              청소가 필요할 땐 헬퍼잇클린!
              <img src={arrow} alt="" width={30} height={30} />
            </button>
          </div>
        </div>
        <div className="sec01_img">
          <img src={sec01} alt="" />
        </div>
      </div>
    </section>
  );
}
