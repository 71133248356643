import React from "react";
import Slider from "react-slick";

import "../assets/css/service.css";
import "../assets/css/certificate.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import AnimatedNumber from "react-animated-number";
import StringUtils from "../utils/StringUtils";
import cert1 from "../assets/images/cert1.jpg";
import cert2 from "../assets/images/cert2.jpg";
import cert3 from "../assets/images/cert3.jpg";
import cert4 from "../assets/images/cert4.jpg";
import cert5 from "../assets/images/cert5.jpg";
import patent1 from "../assets/images/patent1.jpg";
import patent2 from "../assets/images/patent2.jpg";
import certificate from "../assets/images/service.png";
import { slideSettings } from "../utils/modules";

const certs = [certificate, cert1, cert2, cert3, cert4, patent1, patent2];

function CertificateBox({ image }) {
  return (
    <div className="frame" style={{ margin: "1em" }}>
      <img src={image} className="certificate content" alt="신분증" />
    </div>
  );
}

export default function Service() {
  return (
    <div id="section-2">
      <div className="section-in">
        <div className="top">
          <CertificateBox image={cert5} />
          <div className="right">
            <div className="first">
              <h2>총 일거리 건수</h2>
              <h1>
                <AnimatedNumber
                  component={"strong"}
                  value={16023}
                  duration={1500}
                  formatValue={(e) =>
                    StringUtils.numberWithCommas(e.toFixed(0))
                  }
                />
              </h1>
              <h3>2024년</h3>
            </div>
            <div className="second">
              <h4>소중한 시간을 어떻게 하면 효율적으로 사용할 수 있을까?</h4>
              <h5>
                헬퍼잇은, 위치기반 기술과 타임 쉐어링으로
                <br />
                고객님의 소중한 시간을 지켜드립니다.
              </h5>
            </div>
          </div>
        </div>
        <Slider {...slideSettings}>
          {certs.map((item, index) => (
            <CertificateBox key={index} image={item} />
          ))}
        </Slider>
      </div>
    </div>
  );
}
