import React from "react";
import logo from "../assets/images/img-logo.svg";
import youtube from "../assets/images/ico-youtube.svg";
import facebook from "../assets/images/ico-facebook.svg";
import insta from "../assets/images/ico-insta.svg";
import naver from "../assets/images/ico-naver.svg";
import kakao from "../assets/images/ico-kakao.svg";
import busan from "../assets/images/busan.png";
import venture from "../assets/images/venture.png";
import { handlePolicy, handleService } from "../utils/modules";

export default function Footer() {
  return (
    <footer>
      <div className="footer_inner width140 flex justify">
        <div className="footer_left">
          <div className="footer_logo mb40 flex">
            <img src={logo} alt="" />
            <img src={busan} alt="" className="busan-logo" />
            <img src={venture} alt="" className="venture-logo" />
          </div>
          <ul className="footer_menu mb40 flex">
            <li onClick={handleService}>이용약관</li>
            <li onClick={handlePolicy}>개인정보처리방침</li>
          </ul>
          <ul className="footer_txt">
            <li className="mb30">
              {"상호 : 주식회사 불타는 고구마 "}
              <br className="break-m" />
              <p className="d-only">|</p>
              {" 대표이사 : 최석현 "}
              <p className="d-only">|</p>
              <br className="break-m" />
              {" 개인정보관리책임자 : 박창현"}
              <br /> <br className="break-m" />
              {"제휴문의 : csh@bgogooma.com "}
              <br className="break-m" />
              <p className="d-only">|</p>
              {" 사업자등록번호 : 803-87-02223"}
              <br />
              {"통신판매 : 제 2021-부산수영-0894 호 "}
              <br className="break-m" />
              <p className="d-only">|</p>
              {" 직업정보제공사업 신고번호 : "}
              <br className="break-m" />
              {"부산동부 제2024-2호"}
            </li>
            <li className="mb30">
              {"부산 해운대구 삼어로 207-1 303호"}
              <br />
              {"심부름 문의 / 헬퍼파트너 문의 : "}
              <br className="break-m" />
              {"010-6660-0234 "}
              <br className="break-m" />
              <p className="d-only">|</p>
              {" 대표 전화번호 : 1800-9323"}
            </li>
            <li className="mb30">
              {"(주)불타는고구마는 통신판매중개자로서 "}
              <br className="break-m" />
              {"통신판매의 당사자가 아니며"}
              <br />
              {"개별 판매자가 제공하는 서비스에 대한 "}
              <br className="break-m" />
              {"이행, 계약사항 등과 관련한"}
              <br />
              {"의무와 책임은 거래당사자에게 있습니다."}
            </li>
            <li>
              {"Copyright ⓒ 2024 (주)불타는고구마 "}
              <br className="break-m" />
              {"All Rights Reserved."}
            </li>
          </ul>
        </div>
        <div className="footer_right">
          <ul className="sns_wrap flex alignC">
            <li>
              <a href="https://www.youtube.com/@user-up1vl2rt8s">
                <img src={youtube} alt="" />
              </a>
            </li>
            <li>
              <a href="https://www.facebook.com/helperit1">
                <img src={facebook} alt="" />
              </a>
            </li>
            <li>
              <a href="https://www.instagram.com/helperit_official/">
                <img src={insta} alt="" />
              </a>
            </li>
            <li>
              <a href="https://blog.naver.com/bgogooma/">
                <img src={naver} alt="" />
              </a>
            </li>
            <li>
              <a href="https://pf.kakao.com/_wnGxos">
                <img src={kakao} alt="" />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
}
