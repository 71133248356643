import { isMobile } from "react-device-detect";

export const appleLink = "https://apps.apple.com/kr/app/id6462087677";
export const appStore = "itms-apps://apps.apple.com/app/id6462087677";
export const androidLink =
  "https://play.google.com/store/apps/details?id=kr.co.helperits.android";
export const playStore = "market://details?id=kr.co.helperits.android";

export async function sendDiscord(params) {
  try {
    return await fetch(
      `https://discord.com/api/webhooks/${process.env.REACT_APP_WEBHOOK_ID}/${process.env.REACT_APP_WEBHOOK_TOKEN}`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(params),
      }
    );
  } catch (err) {
    throw new Error(err.message);
  }
}

export function handleService() {
  window.location.href = "https://policy.bgogooma.com/service";
}

export function handlePolicy() {
  window.location.href = "https://www.policy.bgogooma.com/policy";
}

export function cleanHandler() {
  window.location.href = "https://clean.helperit.co.kr/";
}

export function appleHandler() {
  if (isMobile) {
    window.open(appStore, "_blank");
  } else {
    window.open(appleLink, "_blank");
  }
}

export function googleHandler() {
  if (isMobile) {
    window.open(playStore, "_blank");
  } else {
    window.open(androidLink, "_blank");
  }
}

export function goMarket() {
  const mobileType = navigator.userAgent.toLowerCase();
  if (
    mobileType.includes("iphone") ||
    mobileType.includes("ipad") ||
    mobileType.includes("ipod") ||
    mobileType.includes("mac")
  ) {
    window.location.replace(appleLink);
  } else {
    window.location.replace(androidLink);
  }
}

export const slideSettings = {
  slidesToShow: 4,
  slidesToScroll: 1,
  centerMode: true,
  autoplay: true,
  autoplaySpeed: 1500,
  dots: false,
  arrows: false,
  initialSlide: 2,
  responsive: [
    {
      breakpoint: 1024,
      settings: { slidesToShow: 3, slidesToScroll: 3, infinite: true },
    },
    {
      breakpoint: 600,
      settings: { slidesToShow: 2, slidesToScroll: 2, initialSlide: 2 },
    },
    {
      breakpoint: 480,
      settings: { slidesToShow: 1, slidesToScroll: 1 },
    },
  ],
};

export function validateForm(formState) {
  const { name, contact, details } = formState;
  const validations = [
    { field: name, message: "이름을 입력하세요" },
    { field: contact, message: "연락처를 입력하세요" },
    { field: details, message: "요청사항을 입력하세요" },
  ];
  for (const { field, message } of validations) {
    if (!field) {
      return message;
    }
  }
}

export function toTopHanlder() {
  const btn = document.querySelector("#button");
  const scrollHandler = () => {
    if (window.scrollY > 300) {
      btn.classList.add("show");
    } else {
      btn.classList.remove("show");
    }
  };
  const clickHandler = (e) => {
    e.preventDefault();
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  window.addEventListener("scroll", scrollHandler);
  btn.addEventListener("click", clickHandler);
}
